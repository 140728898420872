$med-grey: #575757;
$red: #621b23;
$orange: #fc9837;
$med-brown: #58362a;
$large-font-family: myriad-pro, helvetiva, sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font-family: $large-font-family;
  text-rendering: optimizeLegibility;
  color: $med-grey;
  font-size: 100%;
  font-weight: 400;
  line-height: 145%;
  min-height: 100%;
  position: relative;
}

img {
  border: 0;
}

html {
  height: 100%;
}
a {
  color: $red;
  font-weight: 700;
  text-decoration: none;
  transition: opacity 0.4s ease-out 0s;
  outline: none;
}
a:hover {
  color: $orange;
}
p {
  margin: 1.25em 0;
}

header {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.85);
  border-top: 0.4em solid $med-brown;
  border-bottom: 0.1em solid $med-brown;
  box-shadow: 0 0.1em 0.1875em rgba(0, 0, 0, 0.15);
  height: 3.5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}
header nav {
  margin: 0 auto;
  max-width: 62.5em;
  height: 2em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
header nav a {
  display: block;
  width: 33%;
  height: 3em;
  line-height: 3em;
  align-self: flex-start;
  border-left: 0.0625em solid rgba(0, 0, 0, 0.1);
  box-shadow: 0.0625em 0 0 rgba(255, 255, 255, 0.5) inset;
  color: $med-brown;
  display: block;
  text-align: center;
  text-shadow: 0 0.0625em 0 rgba(255, 255, 255, 0.25);
}

header nav a:last-child {
  border-right: 0.0625em solid rgba(0, 0, 0, 0.1);
}

header nav a:hover {
  background-image: radial-gradient(
    ellipse farthest-corner at center bottom,
    #ffffff 0%,
    rgba(255, 255, 255, 0.01) 100%
  );
  color: $red;
}

section.splash {
  background-image: url(./assets/images/bigGreg.jpg);
  background-size: cover;
  background-size: 75em Auto;
  background-repeat: no-repeat;
  height: 40em;
  background-position: 0 0;
  background-color: #fffcf0;
  padding-left: 58%;
  padding-top: 12.5em;
}
section.splash .logo {
  width: 28.125em;
}
section.splash p {
  display: block;
  font-size: 2.2em;
  color: $red;
  font-family: $large-font-family;
  width: 13em;
  text-align: justify;
  text-justify: inter-character;
  margin-top: 1.25em;
  font-weight: 400;
}

section.splash p:after {
  content: "";
  display: inline-block;
  width: 100%;
}

section#about,
footer {
  background: #2b1711 url(./assets/images/aboutBG.jpg) top left repeat-x;
  padding: 5em 15%;
  border-top: 0.125em solid$med-brown;
  border-bottom: 0.125em solid$med-brown;
}
section#about h1 {
  color: #e4cfb5;
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 400;
}
section#work {
  padding: 5em 15%;
}
section#work p {
  color: $med-brown;
  font-size: 2em;
  line-height: 1.5em;
  margin: 0 0 3.75em;
}

section#illustrations {
  flex-flow: row wrap;
  justify-content: space-between;
}

section#illustrations img {
  flex-grow: 4;
  flex-shrink: 1;
  border-radius: 0.25em;
  background: none repeat scroll 0 0 #fff;
  border: 0.1em solid rgba(0, 0, 0, 0.2);
  margin: 0.5em;
  padding: 0.375em;
}

footer a {
  color: #e4cfb5;
  line-height: 3.75em;
  opacity: 0.6;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
}
footer a:hover {
  opacity: 1;
}
.group:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
.fancybox-title {
  text-align: center;
}

// make pull request to adobe-animate-react for this
div#hero div div div {
  display: none !important;
}

@media screen and (max-width: 90.625em) {
  section.splash {
    padding-top: 11.25em;
    height: 30.9375em;
  }
  section.splash .logo {
    width: 21.875em;
  }
  section.splash p {
    width: 20em;
    font-size: 2em;
    text-align: justify;
  }

  section.splash p:after {
    display: block;
  }
}
@media screen and (max-width: 75em) {
  section.splash {
    background-size: 62.5em Auto;
    height: 28.75em;
    background-position: 0 0;
    padding-top: 6.25em;
  }
  section.splash .logo {
    width: 20em;
  }
  section.splash p {
    width: 13em;
    text-align: justify;
  }
}
@media screen and (max-width: 60em) {
  section.splash {
    background-size: 50em Auto;
    height: 21.875em;
    background-position: -6.25em 0;
    padding-top: 6.25em;
  }
  section.splash .logo {
    width: 16.25em;
  }
  section.splash p {
    font-size: 1.4em;
    width: 12em;
    margin-top: 0.625em;
    text-align: center;
  }
}
@media screen and (max-width: 40em) {
  #about,
  #work,
  #blog,
  #contact {
    margin-left: auto;
    margin-right: auto;
    max-width: 38.75em;
  }
  section.splash {
    background-size: 50em Auto;
    height: 21.875em;
    background-position: -12.5em 0;
    padding-top: 6.25em;
  }
  section.splash .logo {
    width: 11.25em;
  }
  section.splash p {
    font-size: 1em;
  }
  section#about,
  section#work {
    padding: 2.5em 15%;
  }
  section#about h1,
  section#work p {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 30em) {
  section.splash {
    background-size: 50em Auto;
    height: 18.75em;
    background-position: -12.5em -1.875em;
    padding-top: 6.25em;
  }
  section.splash .logo,
  section.splash p {
    display: none;
  }

  section#illustrations img {
    width: 100%;
  }
}
@media screen and (max-width: 25em) {
  //   section.work ul li {
  //     height: 84%;
  //     width: 84%;
  //   }
  //   section.work ul li a img {
  //     width: 100%;
  //     height: 100%;
  //   }
}
@media screen and (max-width: 20em) {
  //   section.about,
  //   section.work {
  //     padding: 1.875em;
  //     margin: 0;
  //   }
  //   section.about h1,
  //   section.work h1 {
  //     font-size: 1.1em;
  //   }
  //   header nav div {
  //     margin: 0 auto;
  //     width: 21.25em;
  //   }
  //   #top {
  //     height: 3.75em;
  //   }
}
.fancybox-inner img {
  border-radius: 0.25em;
}
.animated {
  animation-duration: 1s;
}
.animated.hinge {
  animation-duration: 2.2s;
}
.slow {
  animation-delay: 0.5s;
}
.superslow {
  animation-delay: 1.5s;
}

.title h1 a img:hover {
  animation-name: spin;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}

@keyframes bigBounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.bigBounceIn {
  animation-name: bigBounceIn;
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounceInDown {
  animation-name: bounceInDown;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
